.container {
  display: flex;
}

.text {
  margin: 0;
}

.input {
  margin-right: 0.5rem;
  max-width: 2.5rem;
}
