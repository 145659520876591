.root {
  background-color: var(--white-50);
  padding: 1.875rem;
  margin-top: 0.75rem;
  position: relative;
}

.handling {
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 1fr 3fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  align-items: flex-start;
}

.actions {
  display: flex;
  justify-content: space-between;
}

.actionsEditable {
  margin-top: 1.875rem;
}

.actionsPositionFixed {
  position: absolute;
  right: 1.875rem;
  bottom: 1.875rem;
}

.medium::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 3px solid var(--sun-base);
}

.high::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 3px solid var(--orange-base);
}

.highest::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 3px solid var(--pink-base);
}

.deleteIcon {
  margin-right: 0.5rem;
  color: var(--pink-base);
}

.deleteText {
  color: var(--pink-base);
}
