.root {
  padding-right: 1rem;
  padding-left: 1rem;
  color: var(--accent-base);
  font-size: 0.875rem;
  border: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  height: 2.5rem;
  display: flex;
  align-items: center;
  background: none;
}

.root:hover,
.active {
  background-color: var(--accent-basePlus90);
}

.root:not(:last-child) {
  border-bottom: 1px solid var(--ambient-basePlus90);
}
