.root {
  margin: 0;
  padding: 1.25rem;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: calc(100% + 8px);
  background-color: white;
  z-index: 100;
  left: 0;
  right: 0;
}
