.icon {
  color: var(--accent-base);
}

.doorIconStateClosed {
  color: var(--ambient-base);
}

.switchWrapper {
  display: flex;
  align-items: center;
}

.switchLabel {
  font-size: 1.125em;
  line-height: 1.5em;
  font-weight: 600;
  color: var(--ambient-base);
  padding-left: 0.5rem;
}

.switch {
  display: flex;
  align-items: center;
}
