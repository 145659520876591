.root {
  margin-top: 0.1875rem;
  overflow: hidden;
}

.toggleButton {
  color: var(--accent-base);
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
}

.icon {
  padding: 0.2rem;
  margin-right: 0.8rem;
}

.title {
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.address {
  font-size: 0.75rem;
  font-style: italic;
  margin-left: 2.375rem;
  padding-top: 0.25rem;
  padding-bottom: 0.8125rem;
}

.removeButton {
  margin-left: 1.475rem;
  color: var(--pink-base);
}

.removeButton:hover {
  color: var(--pink-baseMinus25);
}

.removeIcon {
  margin-right: 0.5rem;
}
