.wrapper {
  z-index: 999;
  width: 100%;
  max-height: 40vh;
  overflow-y: scroll;
  font-size: 0.75rem;
  line-height: 1.125rem;
  box-sizing: border-box;
  user-select: none;
}

.wrapperButton > :last-child {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem 0;
}

.wrapper h4 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.wrapper.shade,
.wrapper.ambient {
  background-color: var(--color-white);
  color: var(--ambient-base);
}

.wrapper.accent {
  background-color: var(--accent-base);
  color: var(--color-white);
}

.noResults {
  padding-left: 2rem;
}
