.borderBottom {
  border-bottom: 1px solid var(--base10);
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  padding-left: 1.875rem;
}

.checkboxWithComment {
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 1rem;
}

.radioGroup > label {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5625rem;
}

.buttonGroup > :first-child {
  margin-bottom: 0.625rem;
}

.infoText {
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  padding: 1.5625rem;
}

.reasonText {
  font-size: 0.875em;
  margin-right: 1rem;
  margin-top: 0.35rem;
}

.actionGroup {
  padding-bottom: 2.1875rem;
  padding-top: 2.1875rem;
  padding-left: 2.8125rem;
  padding-right: 2.8125rem;
}

.muteDurationError {
  color: var(--pink-base);
  display: flex;
  flex-direction: column;
  align-items: center;
}
