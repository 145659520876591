.rowExpanded {
  display: inherit;
  padding: 0;
}

.contentInner {
  padding-left: 1rem;
}

.col {
  flex: 1;
}

.col2 {
  flex: 2;
}

.col4 {
  flex: 4 10rem;
}

.invalidSentinel {
  color: var(--ambient-basePlus50);
  background-color: var(--shade-05);
}

.borderLeft {
  border-left: 1px solid var(--base10);
}

.redIcon {
  color: var(--pink-base);
  user-select: none;
  cursor: pointer;
}
