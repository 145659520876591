.flex {
  display: flex;
  flex-direction: column;
}

.online,
.Online {
  color: var(--dark-base);
}

.offline,
.Offline {
  color: var(--pink-base);
}

.buffer,
.Buffer {
  color: var(--medium-base);
}
