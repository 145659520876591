.filterSelected {
  color: var(--accent-base);
}

.filterTotal:before {
  content: '/';
  padding-left: 0.2rem;
  padding-right: 0.2rem;
}

.button {
  background: none;
  border: none;
  color: var(--accent-base);
  cursor: pointer;
}
