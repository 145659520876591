.root {
  width: 1.625rem;
  height: 1.625rem;
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  background-color: var(--blue-base);
  color: var(--color-white);
}
