.root {
  position: relative;
}

.wrapper {
  margin-bottom: 2rem;
}

.hero {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  background-color: var(--accent-transparent);
}

.hero > * {
  z-index: 2;
}

.hero:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.65;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.icon {
  color: var(--accent-base);
  padding-bottom: 1rem;
}

.title {
  margin-top: 0;
  font-size: 1.5rem;
}

.heroContent {
  text-align: center;
}

.close {
  position: absolute;
  right: 2.8125rem;
  top: 2.8125rem;
}
