.button {
  width: inherit;
  color: inherit;
  background-color: transparent;
  padding: 0;
}

.button + .button {
  padding-left: 0.5rem;
}

.button:hover {
  background-color: transparent;
  color: inherit;
}

.root {
  line-height: 1.875rem;
  text-transform: capitalize;
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 1rem;
}

.active {
  background-color: var(--accent-basePlus90);
  color: var(--accent-base);
}
