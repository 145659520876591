.root {
  position: relative;
  max-height: 100%;
}

.margin {
  padding: 3.5rem;
}

.hasFilterBar {
  padding: 2.5rem 3.5rem;
}
