.root {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-weight: bold;
  margin-left: 0.5rem;
}
