.metabolicRates {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-left: 1px;
  margin-top: 1px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.card {
  cursor: pointer;
  text-align: center;
  outline: none;
  position: relative;
  padding: 1.375rem;
  background-image: linear-gradient(
      var(--ambient-basePlus75),
      var(--ambient-basePlus75)
    ),
    linear-gradient(var(--ambient-basePlus75), var(--ambient-basePlus75));
  background-repeat: no-repeat;
  background-size: 1px calc(100% - 20px), calc(100% - 20px) 1px,
    calc(100% - 1px) calc(100% - 1px);
  background-position: right 10px, 10px bottom, 1px 1px;
  margin-left: -1px;
  margin-top: -1px;
}
.card:hover {
  background: var(--color-white);
  z-index: 10;
}

.icon {
  background-color: var(--color-white);
  margin: 2rem auto;
  padding: 1rem;
  display: inline-block;
}

.cardTitle {
  margin-top: 0;
  font-size: 0.875rem;
}

.cardDescription {
  font-size: 0.75rem;
  line-height: 1.3;
  margin-bottom: 0;
}

.cardSelected,
.cardSelected:hover {
  background: linear-gradient(
    to right bottom,
    var(--turquoise-basePlus25),
    var(--turquoise-baseMinus50)
  );
  color: var(--color-white);
}

.cardSelected h3 {
  color: var(--color-white);
}

.cardSelected .iconWrapper {
  color: var(--color-white);
}

.cardSelected .icon {
  color: var(--ambient-base);
}

.iconWrapper {
  color: var(--accent-base);
  margin-bottom: 1rem;
}
