.root {
  display: flex;
  padding-bottom: 2rem;
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  align-items: center;
}

.step {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.stepCompleted {
  cursor: pointer;
}

.step:first-child {
  margin-left: 0;
}

.step:last-child {
  margin-right: 0;
}

.currentStepNumber {
  border-radius: 50%;
  color: var(--color-white);
  background-color: var(--ambient-basePlus75);
  width: 2.25rem;
  height: 2.25rem;
  font-weight: 400;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.currentStepNumberActive {
  background: linear-gradient(
    to right bottom,
    var(--turquoise-basePlus25),
    var(--turquoise-baseMinus50)
  );
  color: var(--color-white);
}

.currentStepNumberCompleted {
  background-color: var(--ambient-base);
  color: var(--color-white);
}

.currentStepText {
  display: flex;
  color: var(--ambient-basePlus75);
  margin-left: 1rem;
  line-height: 1rem;
}

.currentStepTextActive,
.currentStepTextCompleted {
  color: var(--ambient-base);
}

.spacer {
  width: 1.875rem;
}

.dashes {
  border-color: var(--ambient-basePlus75);
  border-top-style: dashed;
  border-top-width: 2px;
}

.dashesCompleted {
  border-color: var(--ambient-base);
}
