.root {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  grid-gap: 0.5rem;
}

.controls {
  display: grid;
  grid-template-columns: 6.25rem 6.25rem;
  grid-gap: 0.5rem;
  justify-content: center;
}

.tip {
  display: flex;
}

.finishLaterButtonContainer {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}

.grayBackground {
  background-color: rgba(251, 252, 251, 0.05);
}
