.log {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 1rem;
  grid-area: log;
}

@media (min-width: 30rem) {
  .log {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: 'icon log message action';
  }
}

.log:not(:last-child) {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
}

.title + .message {
  margin-top: 0.625rem;
}

.regular {
}

.normal {
}

.medium {
  color: var(--sun-base);
}

.high {
  color: var(--orange-base);
}

.highest {
  color: var(--pink-base);
}
