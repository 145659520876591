.textAndIconGroupingVertical {
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 25px;
  width: 75px;
}

.validIconStyling .invalidIconStyling .textAndIconGroupingVertical {
  padding-top: 4px;
}

.textAndIconGroupingVertical .validIconStyling {
  color: var(--accent-base);
}

.invalidIconStyling {
  color: var(--ambient-basePlus50);
}

.horizontalGrouping {
  display: flex;
  flex-direction: row;
  align-items: center;
}
