.root {
  display: flex;
  width: 4rem;
}

.item {
  flex: 1;
}

.separator {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
