.row {
  font-size: 0.875rem;
  position: relative;
  padding: 0.625rem 0.625rem 0.625rem 1.625rem;
  color: var(--ambient-base);
}

.grid {
  align-items: center;
  grid-gap: 1rem;
  display: grid;
}

.row:not(:first-child) {
  align-items: start;
}

.disabled {
  background-color: var(--base5);
  color: var(--medium-base);
}

.selectable {
  cursor: pointer;
}

.expanded {
  display: inherit;
  padding: 0;
}
