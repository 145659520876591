/* {
    container,            // The container element
    wrapper,              // The wrapper element, used for keyboard interaction
    interactionDisabled,  // Added to the container when there's no interaction with the calendar
  
    navBar,         // The navigation bar with the arrows to switch between months
    navButtonPrev,  // Button to switch to the previous month
    navButtonNext,  // Button to switch to the next month
    navButtonInteractionDisabled,  // Added to the navbuttons when disabled with fromMonth/toMonth props
  
    months,         // Container of the months table
    month,          // The month's main table
    caption,        // The caption element, containing the current month's name and year
    weekdays,       // Table header displaying the weekdays names
    weekdaysRow,    // Table row displaying the weekdays names
    weekday,        // Cell displaying the weekday name
    body,           // Table's body with the weeks
    week,           // Table's row for each week
    day,            // The single day cell
  
    footer,         // The calendar footer (only with todayButton prop)
    todayButton,    // The today button (only with todayButton prop)
  
    today,          // Added to the day's cell for the current day
    selected,       // Added to the day's cell specified in the "selectedDays" prop
    disabled,       // Added to the day's cell specified in the "disabledDays" prop
    outside,        // Added to the day's cell outside the current month
  }
*/

.wrapper {
  position: relative;
}

.wrapper > div:not(.navBar) {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  background-color: white;
  padding: 1rem;
}

.navBar {
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1.2rem;
}

.navButtonPrev {
  position: absolute;
  left: 0;
}
.navButtonNext {
  position: absolute;
  right: 0;
}

.navButtonColor {
  color: var(--accent-base);
}

.month {
  min-height: 18rem;
}

.caption {
  padding: 0.5rem;
  color: var(--ambient-base);
  text-align: center;
}

.weekdays {
}

.weekdaysRow {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.weekday {
  font-size: 0.6875rem;
  text-transform: uppercase;
  text-align: center;
  color: var(--medium-base);
  padding: 0.5rem;
}

.weekday abbr[title] {
  text-decoration: none;
}

.body {
}

.week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.day {
  padding: 0.75rem;
  text-align: center;
  font-size: 0.75rem;
}

.day:not(.disabled):not(.outside):hover {
  cursor: pointer;
  background-color: var(--accent-basePlus90);
  border-radius: 50%;
}

.day.selected:not(.selectedStart):not(.selectedEnd):hover {
  border-radius: 0;
}

.outside {
  color: var(--ambient-basePlus75);
}

.selected:not(.outside) {
  color: var(--accent-base);
  background-color: var(--accent-basePlus90);
}

.selectedStart:not(.outside),
.selectedEnd:not(.outside) {
  position: relative;
  color: white;
  z-index: 1;
}

.selectedStart:not(.outside) {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.selectedEnd:not(.outside) {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.selectedStart:not(.outside):before,
.selectedEnd:not(.outside):before,
.selectedStart:not(.outside):after,
.selectedEnd:not(.outside):after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
}

.selectedStart:not(.outside):before,
.selectedEnd:not(.outside):before {
  border-radius: 50%;
  background-color: var(--accent-base);
  z-index: -1;
}

.selectedStart:not(.outside):after,
.selectedEnd:not(.outside):after {
  background-color: var(--accent-basePlus90);
  z-index: -2;
}

.selectedStart:not(.outside):after {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.selectedEnd:not(.outside):after {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.disabled {
}

.today {
}

.navFooter {
  margin: 0 2rem 2rem;
  text-align: right;
}
