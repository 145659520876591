.root {
  position: relative;
  display: grid;
  grid-template-columns: 3.75rem 1fr 3.75rem;
  grid-template-areas:
    'icon logs logs'
    'icon location action';
  grid-gap: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: var(--shadow05);
  margin: 0.3125rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
}

.disabled {
  box-shadow: inherit;
  background-color: var(--base5);
  color: var(--medium-base);
}

@media (min-width: 48rem) {
  .root {
    grid-template-columns: 3.75rem 1fr 1fr 3.75rem;
    grid-template-areas: 'icon logs location action';
  }
}

.icon {
  grid-area: icon;
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-top: 0.125rem;
}

.logs {
  grid-area: logs;
}

@media (min-width: 30rem) {
  .log {
    grid-template-columns: 3fr 1fr 2fr;
    grid-template-areas: 'icon log message action';
  }
}

.log + .log {
  padding-top: 1rem;
}

.location {
  grid-area: location;
  display: flex;
  justify-content: space-between;
}

.action {
  grid-area: action;
  border-left: 1px solid #eee;
  display: flex;
  justify-content: center;
}

.regular {
}

.normal {
}

.medium::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 3px solid var(--sun-base);
}

.high::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 3px solid var(--orange-base);
}

.highest::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  border-left: 3px solid var(--pink-base);
}

.button {
  padding-left: 0;
  padding-right: 0;
}

.expandButtonText {
  margin-left: 0.35rem;
}

.icon--regular {
}

.icon--normal {
}

.icon--medium {
  color: var(--sun-base);
}

.icon--high {
  color: var(--orange-base);
}

.icon--highest {
  color: var(--pink-base);
}
