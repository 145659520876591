.root {
  position: relative;
}

.selectField {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.selectFieldFocus {
  border-bottom: 1px solid var(--base75);
}

.selectField:focus {
  color: #495057;
  background-color: #fff;
  outline: 0;
  border-bottom: 1px solid var(--base75);
}

.selectFieldInput {
  flex: 1 10rem;
  border-bottom: 0;
}

.selectFieldInput:focus {
  outline: 0;
  border-bottom: 0;
}

.selectFieldResultItem {
  display: flex;
  align-items: center;
  height: 21px;
  background: #eee;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
  padding: 0.25rem;
  line-height: 1;
  font-size: 0.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: 0.5rem;
}

.selectFieldResultItem:hover {
  background-color: #ddd;
}

.item {
  display: flex;
  align-items: center;
}

.avatar {
  margin-right: 1rem;
}
