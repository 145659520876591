.stepWrapper {
  font-size: 0.75rem;
  line-height: 17px;
  color: var(--ambient-base);
  width: 100%;
}

.formHeading {
  border-bottom: 1px solid var(--base25);
  padding-bottom: 0.25rem;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr 1fr 3fr;
  grid-column-gap: 2rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.stepHandling:last-child {
  margin-bottom: 1.25rem;
}

.addEscalation {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.editIcon {
  margin-right: 0.5rem;
}
