.container {
  margin: 0.3125rem 0;
}

.listMode {
  box-shadow: var(--shadow05);
}

.disabled {
  box-shadow: inherit;
}
