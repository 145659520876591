.root {
  display: inline-block;
  font-size: 0.625rem;
  font-weight: bold;
  line-height: 1;
  height: 1rem;
  min-width: 1rem;
  text-align: center;
  border-radius: 0.4375rem;
  padding: 0.1875rem 0.5rem;
  background-color: currentColor;
}

.text {
  color: rgb(255, 255, 255);
}
