.siteListItem {
  display: grid;
  grid-gap: 1rem;
  border-bottom: 1px solid var(--ambient-basePlus75);
  user-select: none;
  padding-bottom: 0.625rem;
}

.siteListItemCollapsed {
  height: 2.125rem;
  overflow: hidden;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  text-align: left;
  color: var(--ambient-base);
}

.table td,
.table th {
  padding: 0.625rem;
  font-size: 0.75rem;
  vertical-align: top;
}

.table th:last-child {
  text-align: center;
}

.table th,
.table td {
  padding-left: 0;
}

.action {
  text-align: center;
}

.actionHeader {
  word-spacing: 0.2rem;
}

.table thead th {
  font-weight: normal;
  vertical-align: bottom;
  border-bottom: 1px solid var(--ambient-basePlus75);
}

.filterRow {
  cursor: pointer;
}

.icon {
  margin-right: 0.5rem;
  color: var(--accent-base);
  min-width: 1rem;
  max-width: 1rem;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3.125rem;
}

.tableLoading {
  padding: 0.625rem;
  font-size: 0.75rem;
}

.iconContainer {
  display: flex;
  align-items: center;
  height: 1rem;
}

.actionContainer {
  display: grid;
  grid-template-columns: 20rem 1fr;
  grid-gap: 1rem;
}

.actionTargetType {
  display: flex;
  align-items: center;
}

.actionTargetType label {
  margin-right: 1rem;
  text-transform: capitalize;
}
