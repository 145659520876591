.wrapper {
  position: relative;
}

.dropDownWrapper {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  list-style: none;
  position: absolute;
  top: calc(100% + 8px);
  background-color: white;
  z-index: 100;
}

.regular {
  min-width: 257px;
}

.large {
  width: 36.25rem;
}

.filterItem {
  text-transform: capitalize;
}
