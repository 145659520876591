.root {
  font-size: 0.75rem;
}

.item {
  display: inline-block;
}

.item:not(:first-child) {
  position: relative;
  padding-left: 1rem;
}

.item:not(:first-child):before {
  content: '';
  border-top: 1px solid var(--accent-base);
  border-right: 1px solid var(--accent-base);
  height: 0.375rem;
  width: 0.375rem;
  position: absolute;
  transform: rotate(45deg);
  top: 0.34375rem;
  left: 0.25rem;
}
