.root {
  max-width: 50rem;
  margin: 0 auto;
}

.container {
  /* background-color: var(--white-50); */
  padding: 1.875rem;
  margin-top: 0.75rem;
  position: relative;
}

.centeredContainer {
  padding: 1.875rem;
  margin-top: 0.75rem;
  text-align: center;
}
