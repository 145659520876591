.header {
  padding: 0;
}

.columnNotification {
  flex: 1 14rem;
}

.columnValue {
  flex: 1 2rem;
}

.columnDate {
  flex: 1 4rem;
}

.columnSource {
  flex: 3 13rem;
}

.columnAction {
  flex: 1;
}

.cellTitle {
}

.cellValue {
  font-weight: bold;
}

.cellDate {
  color: var(--accent-base);
  font-style: italic;
}

.tooltipDate {
  font-style: italic;
}

.messageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3.125rem;
}

.actions {
  margin: 2rem;
  text-align: center;
}
