.root {
  text-transform: capitalize;
}

.circle {
  background-color: currentColor;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.5rem;
  display: inline-block;
  margin-left: 0.5rem;
  margin-bottom: 1px;
}

.normal {
}

.medium {
  color: var(--sun-base);
}

.high {
  color: var(--orange-base);
}

.highest {
  color: var(--pink-base);
}
