.root {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1rem;
  padding: 1rem;
  background-color: var(--white-50);
  /* transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
}

.root:hover {
  /* background-color: var(--shade-05); */
}

.root + .root {
  margin-top: 1rem;
}

.timeSelect {
  display: flex;
  justify-content: space-between;
}

.timeSelectDelete {
  flex: 1;
}

.deleteIcon {
  margin-right: 0.5rem;
  color: var(--pink-base);
}

.controls {
  margin-bottom: 0.5rem;
}

.timeSelectSeparator {
  margin-left: 1rem;
  margin-right: 1rem;
}

.timeRanges {
  padding-bottom: 0.5rem;
}

.iconWithText {
  display: flex;
  align-items: center;
}

.addIcon {
  color: var(--accent-base);
  margin-right: 0.5rem;
}
