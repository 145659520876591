.listCell {
  white-space: nowrap;
  line-height: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centered {
  text-align: center;
}

.withIcon {
  padding-left: 2.5rem;
}

.allowOverFlow {
  overflow: inherit;
}
