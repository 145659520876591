.labelWrapper {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: var(--accent-base);
  height: 1.875rem;
}

.labelWrapper:hover label {
  color: var(--accent-base);
}

.input {
  display: none;
}

.label {
  color: var(--ambient-base);
  font-size: 0.875rem;
  margin-left: 0.625rem;
  align-items: center;
  transition: all 0.2s;
  user-select: none;
}

.icon {
  flex-shrink: 0;
}

.label:hover {
  color: var(--accent-base);
}

.disabled {
  cursor: initial;
}

.disabled,
.disabled .label {
  color: var(--ambient-basePlus75);
}

.disabled .label:hover {
  color: var(--ambient-basePlus75);
}

.fullWidthLabel {
  width: 100%;
}

.fullWidth {
  display: flex;
}
