.root {
  font-size: 0.75rem;
  line-height: 1.125rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
}

.root + .root {
  border-top: 1px solid var(--ambient-basePlus90);
}
