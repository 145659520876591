.listWrapper {
  list-style: none;
  border-bottom: 1px solid var(--base10);
}

:last-child.listWrapper {
  border-bottom: none;
}

.checkbox {
  align-items: center;
}

.checkbox svg {
  align-self: flex-start;
  margin-top: 1.5rem;
  flex-shrink: 0;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  flex-grow: 1;
  padding: 1.25rem 0 0.625rem 0;
  border-bottom: 1px solid var(--ambient-basePlus90);
}

.textWrapper:last-child {
  border: none;
}

.title {
  font-size: 0.875rem;
  line-height: 1.1875rem;
  font-weight: 600;
  color: var(--ambient-base);
}

.description {
  font-size: 0.75rem;
  line-height: 1.1875rem;
  font-weight: 400;
  font-style: italic;
  color: var(--ambient-base);
}

.highlightedText {
  color: var(--turquoise-base);
}
