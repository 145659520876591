.root {
  padding-top: 0.25rem;
  padding-bottom: calc(0.25rem + 1px);
  line-height: 1.5;
  font-size: 0.875rem;
  margin: 0;
}

.title {
  font-weight: bold;
}
