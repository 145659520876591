.root {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
}

.root:not(:last-child) {
  border-bottom: 1px solid var(--ambient-basePlus90);
}
