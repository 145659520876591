.centerCheckbox {
  display: flex;
  justify-content: center;
  padding: 12.5px;
  padding-left: 20px;
  padding-right: 20px;
  border-left: 1px solid var(--ambient-basePlus50);
}

.defaultCursor {
  cursor: default;
}

.tablePaddingTop {
  padding-top: 30px;
}

.dropdownPaddingBottom {
  padding-bottom: 2.5rem;
}

.dateTextStyle {
  color: var(--accent-base);
}

.dontWrap {
  white-space: nowrap;
}

.whiteBackground {
  background-color: #ffffff;
}
