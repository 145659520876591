.input {
  width: 100%;
  padding: 0.25rem 0;
  background: none;
  border: none;
  border-bottom: 1px solid var(--base25);
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--ambient-base);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input::after {
  content: '';
}

.input:focus {
  border-bottom: 1px solid var(--base75);
  outline: none;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
}

.errorInput {
  border-color: var(--pink-base);
  background-color: var(--pink-basePlus90);
}

.input::-webkit-input-placeholder {
  color: var(--ambient-basePlus50);
  font-size: 0.875rem;
  line-height: 1.125;
  font-weight: 400;
}

.isTitle {
  font-weight: 700;
}

.isMainTitle,
.isMainTitle::-webkit-input-placeholder {
  font-size: 1.5rem;
  height: 2.5rem;
  font-weight: 700;
}
