.root {
  position: relative;
}

.select {
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: calc(0.25rem + 1px);
  background: none;
  border: none;
  font-size: 0.875rem;
  line-height: 1.5;
  color: var(--ambient-base);
  border-radius: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 1rem;
}

.selectorIcon {
  position: absolute;
  height: 1.875rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--accent-base);
}
