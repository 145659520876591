.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding-bottom: 1.25rem;
  margin-bottom: 1.875rem;
  border-bottom: 1px solid var(--base10);
}

.filterMenu {
  display: flex;
}

.filterMenu > div:not(:last-child) {
  padding-right: 0.625rem;
}
